import { Navigation, Autoplay, EffectCreative } from "swiper";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import tw, { styled } from "twin.macro"
// import Image from 'react-image'
import "swiper/css";
import "swiper/css/navigation";
import "../css/service.css";
import "../css/gallery.css";
import "../css/home.css";
import Footer from "../components/footer";
import NavBar from "../components/navbar";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div>
          <NavBar />
        </div>
        {/* <div className="container"> 
                    <img className="banner-background" src='../../banner.jpg' />
                        <div className="banner-split">
                            <div className="banner-split-left">
                                <div className="banner-split-left-content">
                                   <title className="banner-split-left-title"></title>
                                   <p className="banner-split-left-description"></p>
                                </div>
                            </div>
                            <div className="banner-split-right"></div>
                        </div>
                </div>*/}
        <Swiper
          modules={[Navigation, Autoplay, EffectCreative]}
          spaceBetween={50}
          navigation
          loop={true}
           autoplay={{
             delay: 4000,
             disableOnInteraction: false,
           }}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <section>
              <div className="banner-color text-white lg:pt-40 pt-20 pb-14 lg:h-screen">
                <div className="container mx-auto lg:pt-8 pt-0">
                  <div className=" flex flex-col md:flex-row items-center box-rev">
                    <div className="flex flex-col w-full lg:w-1/3 justify-center items-start px-10 lg:p-8">
                      <h1 className="text-3xl md:text-3xl font-bold text-yellow-400 tracking-loose uppercase">
                        Fitlane Sports
                      </h1>
                      <h2 className="text-3xl md:text-3xl leading-relaxed md:leading-snug mb-2 text-etrabold uppercase">
                        Best In-School physical education & Sports Programs{" "}
                      </h2>
                      <p className="text-sm md:text-base mb-4">
                        Your Students can Fit
                      </p>
                      <a
                        href="/contact"
                        className="btn-time bg-transparent hover:bg-yellow-400 uppercase text-yellow-400 hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-yellow-400 hover:border-transparent mt-4"
                      >
                        Contact
                      </a>
                    </div>
                    <div className="p-4 lg:p-8 mt-12 mb-36 md:mb-0 md:mt-0 ml-0 md:ml-12 lg:w-2/3 justify-center">
                      <div className="h-48 flex flex-wrap content-center">
                        <div>
                          <img
                            className="inline-block w-52 mt-28 hidden xl:block"
                            src="../banner1-left.png"
                            
                          />
                        </div>
                        <div>
                          <img
                            className="inline-block w-80 mt-24 md:mt-0 p-8 md:p-0"
                            src="../banner1-center.png"
                            
                          />
                        </div>
                        <div>
                          <img
                            className="inline-block w-52 mt-28 hidden lg:block"
                            src="../banner1-right.png"
                            
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            <section>
              <div className="bg-gray-200 text-white pt-20 pb-6 lg:h-screen">
                <div className="container mx-auto flex flex-col md:flex-row items-center my-12 md:my-20">
                  <div className="p-8 mt-12 mb-6 md:mb-0 md:mt-0 ml-0 md:ml-12 lg:w-2/3 justify-center">
                    <div className="h-48 flex flex-wrap content-center">
                      <div>
                        <img
                          className="inline-block w-52 mt-28 hidden xl:block"
                          src="../banner2-right.png"
                          
                        />
                      </div>
                      <div>
                        <img
                          className="inline-block w-80 md:mt-0 p-8 md:p-0"
                          src="../banner2-center.png"
                          
                        />
                      </div>
                      <div>
                        <img
                          className="inline-block w-52 mt-28 hidden lg:block"
                          src="../banner2-left.png"
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-1/3 justify-center items-start px-10 lg:p-8 mt-4">
                    <h1 className="text-3xl md:text-3xl font-bold text-yellow-400 tracking-loose uppercase">
                      Let's play
                    </h1>
                    <h2 className="text-3xl md:text-3xl b-color leading-relaxed md:leading-snug mb-2 text-etrabold uppercase">
                      I can do things you connot
                    </h2>
                    <h2 className="text-3xl md:text-3xl b-color leading-relaxed md:leading-snug mb-2 text-etrabold uppercase">
                      you can do things I connot
                    </h2>
                    <h2 className="text-3xl md:text-3xl b-color leading-relaxed md:leading-snug mb-2 text-etrabold uppercase">
                      together we can do great things
                    </h2>
                    <p className="text-sm md:text-base b-color mb-4">
                      That is Sports
                    </p>
                    <a
                      href="/about"
                      className="btn-time btn-color bg-transparent uppercase rounded shadow hover:shadow-lg py-2 px-4 mt-4"
                    >
                      About
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>
        </Swiper>
        <section className="w-full pt-12 lg:mb-24 space">
          <div className="container mx-auto">
            <h2 className="text-3xl md:text-3xl text-center mb-2 text-yellow-400 uppercase">
              Fitlane Sports
            </h2>
            <h1 className="text-4xl md:text-4xl font-color text-center uppercase font-extrabold mb-12 lg:mb-24">
              Best Sports Education Organization
            </h1>

            <div className="banner-split grid grid-cols-1 lg:grid-cols-2">
              <div className="banner-split-right col-span-1 lg:mr-2 relative">
                <img src="../sports.jpg"  className="right-image" />
                <div className="image-layout-about w-24 uppercase absolute bg-yellow-400"></div>
              </div>
              <div className="banner-split-left col-span-1 ml-8">
                <div className="banner-split-left-content lg:mt-12 mt-12">
                  <h2 className="text-3xl font-bold md:text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-4xl uppercase">
                    Fitlane Sports Always With You
                  </h2>
                  <p className="mt-4 text-sm md:text-base mb-8">Fitlane Sports helps students to develop their basic skills in sports. We inculcate basic humanities in students. They will learn to help each other. Through this they will also learn Personality Development.</p>
                  <div className="mt-6">
                    <a href="/about" className="bg-transparent rounded shadow hover:shadow-lg py-2 px-4 uppercase btn-color">
                      About Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full mt-12 pt-12 lg:pt-0 lg:mt-0 bg-gray-200 pb-10 -z-10 space">
          <div className="container mx-auto lg:py-20 relative">
            <h2 className="text-3xl md:text-3xl text-center mb-2 text-yellow-400 uppercase">
              Fitlane Sports Secrets
            </h2>
            <h1 className="text-4xl md:text-4xl font-color text-center uppercase font-extrabold lg:mb-24 mb-12">
              Our Formulas
            </h1>
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div className="col-span-1 "></div>
              <div className="col-span-1 lg:mt-8 mt-0">
                <div className="service-info"></div>
              </div>
              <div className="col-span-1 lg:col-span-2 lg:ml-20 lg:mr-12">
                <Swiper
                  breakpoints={{
                    320: {
                      width: 320,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    },
                  }}
                  spaceBetween={15}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div className="h-auto lg:p-0 p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../plan.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                          Plan
                        </h2>

                        <div className="lg:px-6 text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          Instantly plan PE for the year with pre-programmed short, medium and long term plans.

                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="h-auto lg:p-0 p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../track.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                        TRACK
                        </h2>

                        <div className="lg:px-6 text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          Track the progress of students and what can be done for improvement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=" lg:p-0 h-auto p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../assess.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                        assessment
                        </h2>

                        <div className="lg:px-6 text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          Real time assessment and monitoring of students development and progress.

                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=" lg:p-0 h-auto p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../schud.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                        SCHEDULE
                        </h2>

                        <div className="lg:px-6 text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          A fully editable calendar allows you to plan around your other subjects. 

                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=" lg:p-0 h-auto p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../report.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                        REPORT
                        </h2>

                        <div className="lg:px-6 text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          Quickly and easily access whole school, class and children’s reports. 

                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className=" lg:p-0 h-auto p-4">
                      <div className="card w-full bg-white shadow-own rounded-xl box-hei">
                        <img
                          className="lg:w-32 w-24 mx-auto rounded-full -lg:mt-20 border-8 border-white"
                          src="../monitor.png"
                          
                        />
                        <h2 className="text-center text-xl font-bold uppercase">
                        MONITOR
                        </h2>

                        <div className="text-center font-light text-sm">
                          <p className="p-4 text-sm md:text-base">
                          Monitor student’s development in real-time and report on their progress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="homeservice-grid-overlay lg:max-height-400 h-auto absolute"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="space">
          <div className="container mx-auto">
            <div className="mt-12">
              <h2 className="text-3xl md:text-3xl text-center mb-2 lg:mt-0 text-yellow-400 uppercase">
                Mobile Application
              </h2>
              <h1 className="text-4xl  md:text-4xl text-center font-color font-extrabold uppercase">
                Fitlane Sports App
              </h1>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 mb-8">
              <div className="content-left col-span-1 self-center">
                <h3 className="uppercase blod text-2xl mt-4 lg:mt-0">"FITLANE SPORTS" APP</h3>
                <p>
                  An initiative by Fitlane Sports to help school management,
                  parents monitor their child fitness and sports journey in this
                  mobile app.
                </p>
                <h3 className="uppercase blod text-2xl mt-4">FEATURES:</h3>
                <p>
                  * View fitness and skill assessment reports.</p><p> * Know what your
                    child learns in Fitlane Sports classes on a daily basis.</p><p>* Get
                      notifications on Fitlane Sports events and other competitive
                      leagues.
                </p>
              </div>
              <div className="col-span-1 image-right">
                <img className="app-wid" src="../mobileapp.png" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-200 w-full lg:py-12 space">
          <div className="container mx-auto">
            <h2 className="text-3xl md:text-3xl text-center mb-2 pt-12 lg:pt-0 lg:mt-0 text-yellow-400 uppercase">
              Sports Bring to Happiness
            </h2>
            <h1 className="text-4xl  md:text-4xl text-center font-color font-extrabold uppercase">
            Happiness Bring to Health
            </h1>

            <div className="banner-split grid grid-cols-1 box-rev lg:grid-cols-2">
              <div className="banner-split-left self-center col-span-1 mr-8">
                <div className="banner-split-left-content mt-12 mb-16">
                  <h1 className="text-3xl font-bold md:text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-4xl uppercase">
                Sports Quotes
                  </h1>
                  <p className="mt-4 text-sm md:text-base mb-4">
                  Sports is not Only a Form of entertainment, but also -- and above all. I would say -- a tool to communicate values that promote the good that is in humans and help build a more peaceful and fraternal society <br/> 
                  </p>
                  <p class="text-right font-bold">- Pope Francis</p>
                  <div className="mt-6">
                    <a href="/service" className="bg-transparent rounded shadow hover:shadow-lg py-2 px-4 uppercase btn-color">
                      Program
                    </a>
                  </div>
                </div>
              </div>
              <div className="banner-split-right col-span-1 lg:ml-2 ml-0 relative lg:mt-24 mt-12 lg:mb-12">
                <img src="../football.jpg"  className="right-image relative" />
                <div className="image-layout-testimonial w-24 absolute bg-yellow-400"></div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="col-span-1 contact">
              <h1 className="text-center font-extrabold text-3xl uppercase">
                Do You Want To Business With Us
              </h1>
            </div>

            <div className="col-span-1 pad-btn lg:text-center contact">
              <a href="/contact" className="bg-transparent rounded shadow hover:shadow-lg py-2 px-4 uppercase btn-color">
                Contact Us
              </a>
            </div>
          </div>
        </div>

        {/* <Router> 
            <Route path="/footer">
               <footer />
            </Route>
         </Router> */}
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}







// styles
// const pageStyles = { 
//   color: "#232129",
//   padding: 96,
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// }
// const headingStyles = {
//   marginTop: 0,
//   marginBottom: 64,
//   maxWidth: 320,
// }
// const headingAccentStyles = {
//   color: "#663399",
// }
// const paragraphStyles = {
//   marginBottom: 48,
// }
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }
// const listStyles = {
//   marginBottom: 96,
//   paddingLeft: 0,
// }
// const listItemStyles = {
//   fontWeight: 300,
//   fontSize: 24,
//   maxWidth: 560,
//   marginBottom: 30,
// }

// const linkStyle = {
//   color: "#8954A8",
//   fontWeight: "bold",
//   fontSize: 16,
//   verticalAlign: "5%",
// }

// const docLinkStyle = {
//   ...linkStyle,
//   listStyleType: "none",
//   marginBottom: 24,
// }

// const descriptionStyle = {
//   color: "#232129",
//   fontSize: 14,
//   marginTop: 10,
//   marginBottom: 0,
//   lineHeight: 1.25,
// }

// const docLink = {
//   text: "Documentation",
//   url: "https://www.gatsbyjs.com/docs/",
//   color: "#8954A8",
// }

// const badgeStyle = {
//   color: "#fff",
//   backgroundColor: "#088413",
//   border: "1px solid #088413",
//   fontSize: 11,
//   fontWeight: "bold",
//   letterSpacing: 1,
//   borderRadius: 4,
//   padding: "4px 6px",
//   display: "inline-block",
//   position: "relative",
//   top: -2,
//   marginLeft: 10,
//   lineHeight: 1,
// }

// // data
// const links = [
//   {
//     text: "Tutorial",
//     url: "https://www.gatsbyjs.com/docs/tutorial/",
//     description:
//       "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
//     color: "#E95800",
//   },
//   {
//     text: "How to Guides",
//     url: "https://www.gatsbyjs.com/docs/how-to/",
//     description:
//       "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
//     color: "#1099A8",
//   },
//   {
//     text: "Reference Guides",
//     url: "https://www.gatsbyjs.com/docs/reference/",
//     description:
//       "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
//     color: "#BC027F",
//   },
//   {
//     text: "Conceptual Guides",
//     url: "https://www.gatsbyjs.com/docs/conceptual/",
//     description:
//       "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
//     color: "#0D96F2",
//   },
//   {
//     text: "Plugin Library",
//     url: "https://www.gatsbyjs.com/plugins",
//     description:
//       "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
//     color: "#8EB814",
//   },
//   {
//     text: "Build and Host",
//     url: "https://www.gatsbyjs.com/cloud",
//     badge: true,
//     description:
//       "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
//     color: "#663399",
//   },
// ]

// markup
// const IndexPage = () => {
//   return (
//        <main>
         

//          <Banner />
//        </main>
    // <main style={pageStyles}>
    //   <title>Home Page</title>
    //   <h1 style={headingStyles}>
    //     Congratulations
    //     <br />
    //     <span style={headingAccentStyles}>— you just made a Gatsby site! </span>
    //     <span role="img" aria-label="Party popper emojis">
    //       🎉🎉🎉
    //     </span>
    //   </h1>
    //   <p style={paragraphStyles}>
    //     Edit <code style={codeStyles}>src/pages/index.js</code> to see this page
    //     update in real-time.{" "}
    //     <span role="img" aria-label="Sunglasses smiley emoji">
    //       😎
    //     </span>
    //   </p>
    //   <ul style={listStyles}>
    //     <li style={docLinkStyle}>
    //       <a
    //         style={linkStyle}
    //         href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
    //       >
    //         {docLink.text}
    //       </a>
    //     </li>
    //     {links.map(link => (
    //       <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
    //         <span>
    //           <a
    //             style={linkStyle}
    //             href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
    //           >
    //             {link.text}
    //           </a>
    //           {link.badge && (
    //             <span style={badgeStyle} aria-label="New Badge">
    //               NEW!
    //             </span>
    //           )}
    //           <p style={descriptionStyle}>{link.description}</p>
    //         </span>
    //       </li>
    //     ))}
    //   </ul>
    //   <img
    //     src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
    //   />
   

